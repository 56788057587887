import * as React from "react"
import LayoutTmpl from "../components/layout";
import {Helmet} from "react-helmet";
import {styled} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    margin: 'auto',
}));
const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));

const NotFoundPage = () => {

    if (typeof window !== 'undefined' && window.location.pathname.includes('/review-')) {
        let strSearch = window.location.pathname;
        let strFrom = strSearch.search('/review-');
        let strTo = strSearch.length;
        let strCut = strSearch.substring(strFrom+1,strTo);
        let strNew = strSearch.split(strCut)[0];
        window.location.replace("https://ocenivay.com"+strNew);
    }

    return (
        <>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>404 - Page NotFound</title>
                <meta name="description" content="404 - Page NotFound"/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>
            <LayoutTmpl>
                <main>
                    <StyledContainer maxWidth="md">
                        <StyledPaper>
                <div>
                    <h1>404 - Page NotFound</h1>
                    <p>Страница не существует или была удалена. Воспользуйтесь поиском...</p>
                </div>
                        </StyledPaper>
                    </StyledContainer>
                </main>

            </LayoutTmpl>

        </>
    )
}

export default NotFoundPage
